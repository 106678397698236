<template>
  <b-row no-gutters>
    <div :class="badge">
      <div class="badge-text-sm">
        {{ status }}
      </div>
    </div>
  </b-row>
</template>
<script>
export default {
  data: () => ({
    paymentBadge: '',
    paymentStatus: ''
  }),
  props: {
    participantPayment: {
      type: Object,
      default: () => {},
    }
  },
  computed: {
    badge () {
      return this.paymentBadge;
    },
    status () {
      return this.paymentStatus;
    }
  },
  mounted () {
    if (null !== this.participantPayment) {
      if (this.participantPayment.price - this.participantPayment.restToPay === 0) {
          this.paymentBadge = 'badge-available-success-sm';
          this.paymentStatus = this.$t('components.custom.planning.booking-details-modal.general-information.table.paid');
      } else if (this.participantPayment.price - this.participantPayment.restToPay > 0){
        this.paymentBadge = 'badge-available-error-sm';
        this.paymentStatus = this.$t('components.custom.planning.booking-details-modal.general-information.table.not-paid');
      }
    }
  }
}
</script>